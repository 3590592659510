
.cost-text {
	font-size: 12px;
	letter-spacing: normal;
	font-family: Arial;
}

.card-ajust {
	padding-top: 0px;
}

.export {
	font-weight: var(--clr-p1-font-weight, 400);
	font-size: .7rem;
	letter-spacing: normal;
	line-height: 1.2rem;
	font-family: var(--clr-font, Metropolis, "Avenir Next", "Helvetica Neue", Arial,);
	float:right!important;
}

.clear{
    clear:both;
}

.modal-dialog.modal-xl {
	width: 100%;
}

.clr-wizard.wizard-xl .modal-dialog {
	height: 100%;
}