/* You can add global styles to this file, and also import other style files */

/* <GraphOnDemand combobox> */
.clr-combobox-wrapper.multi {
    width: 100%;
    min-width: 100%;
}

.clr-combobox-form-control {
    margin-top: unset;
}
/* </GraphOnDemand combobox> */



/*@media (min-width: 1400px) {*/
@media (min-width: 1600px) {
    .clr-col-xxl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .clr-col-xxl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .clr-col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .clr-col-xxl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .clr-col-xxl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .clr-col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .clr-col-xxl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .clr-col-xxl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .clr-col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .clr-col-xxl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .clr-col-xxl-11 {
        flex: 0 0 91.666666666%;
        max-width: 91.666666666%;
    }

    .clr-col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
/*}*/

/* <DC Netscope netscope-ommon-flowview combobox> */
#column-flow-combobox > netscope-common-flowview-combobox > clr-combobox-container {
    position: relative;
    height: 100%;
    padding-bottom: 5px;
}

#column-flow-combobox > netscope-common-flowview-combobox > clr-combobox-container > .clr-control-container {
    height: 100%;
}

#column-flow-combobox > netscope-common-flowview-combobox > clr-combobox-container > .clr-control-container > clr-combobox > .clr-combobox-wrapper {
    height: 100%;
    border-radius: 0.35rem;
}
/* </DC Netscope netscope-common-flowview combobox> */

/* <Fix icons associated with Dropdown> */
.dropdown .dropdown-toggle {
    display: inline;
}
/* </Fix icons associated with Dropdown> */